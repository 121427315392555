<template>
  <div class="content-container pt-0">
        <div class="landing-header">
        <img class="img-center" src="../assets/images/landing-banner.png" alt="">
    </div>

    <div class="landing-header-title align-end gray-100 mb-0">
      <div class="container flex flex-col flex-auto mlr-20 max-w1440">
        <h1 class="xl">CSS Utility <br>Library</h1>
        <p class="mt-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, voluptate.
        </p>
      </div>
    </div>

    <!-- <section>
      <div class="content-band mt-20 mb-10">
        <h2 class="">Our Approach</h2>
        <p>
          The design philosophy, goals, and general principles of the Ohio
          Design System.
        </p>
      </div>
    </section> -->

    <section>
      <div class="content-band mt-10 flex-grid gap-20">
        <NewTile
          v-for="(element, index) in tileData"
          :key="index"
          :tile="element"
          page="utilities"
        />
      </div>
    </section>
  </div>
</template>

<script>
import NewTile from "../components/NewTile";
export default {
  name: "Utilities",
  data: function() {
    return {
      tileData: [
        {
          icon: "flexbox",
          title: "Flexbox",
          description:
            "Flex utilities can be used to apply flexbox behaviors to elements by using css utility classes",
        },
        {
          icon: "size-spacing",
          title: "Size & Spacing",
          description:
            "Size & spacing utilities can be used to apply height, width, margin and padding to elements.",
        },
        {
          icon: "hover-focus",
          title: "Hover & Focus",
          description: "Utilities for hover and focus behaviors.",
        },
        {
          icon: "font-style",
          title: "Fonts",
          description:
            "Utilities for controling font-size, font-weight, font-color, text-decoration and text-alignment.",
        },
        {
          icon: "image-utility",
          title: "Images",
          description:
            "Image utilities control how an image is displayed within its container.",
        },
        {
          icon: "color-utility",
          title: "Colors",
          description:
            "Design system includes an extended palette with primary colors and tint variations.",
        },
      ],
    };
  },
  components: {
    NewTile,
  },
};
</script>
